<template>
  <div class="techtask">
    <div
      class="techtask__content__title col-11 col-sm-10 text-center m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      {{ techStack }}
    </div>
    <div class="move">
      <div v-for="(img, indx) in tech" :key="indx">
        <div class="">
          <img v-lazy="img" alt="tech" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TechTask',
  data() {
    return {
      techStack: this.$t('home').tglGroup.techStack,
      tech: [
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp',
        '/images/home/tech_1.webp',
        '/images/home/tech_2.webp',
        '/images/home/tech_3.webp',
        '/images/home/tech_4.webp',
        '/images/home/tech_5.webp'
      ]
    };
  },
  mounted() {},
  watch: {
    lang() {
      this.techStack = this.$t('home').tglGroup.techStack;
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
.techtask {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  .techtask__content__title {
    font-size: 40px;
    font-weight: 700;
    color: var(--blue-text);
    z-index: 0;
    margin-bottom: 16px;
  }
  .move {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 50px 0;
    /* transition: all 0.2s linear;
    animation: scrollText 10s linear infinite; */
    animation: scrollImages 80s linear infinite;
    img {
      object-fit: contain;
    }
  }
  @keyframes scrollImages {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @media (max-width: 767.98px) {
    .techtask__content__title {
      font-size: 32px;
    }
  }
}
</style>
